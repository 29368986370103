import { Component, OnInit, AfterViewInit, OnDestroy,  } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { ChartConfiguration } from 'chart.js';

interface ProjectDetails {
  analysis_types: string[];
  project_data: {};
  project_id: string;
  sample_cols: any[];
  samples: any[];
}


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})

export class AnalyticsComponent implements AfterViewInit {
  isLoading = false;
  projectDetails: ProjectDetails[] = [];
  projectAnalysisTypes: string[] = [];
  public projectViewData: any = null;
  selectedProject: string = '';
  selectedProjectDetails!: ProjectDetails;
  selectedAnalysisType: string = '';
  public barChartPlugins = [];

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
  };

  public barChartNovelSupportOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: { text: 'No. of supporting haplotypes', display: true },
      },
      y: {
        display: true,
        title: { text: 'No. of novel alleles', display: true },
        type: 'logarithmic',
      }
    }
  };

  constructor(
    private backendService: BackendService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoading = true;      
      this.backendService.getUserProjectDetails().subscribe((response: any) => {
        if (response.project_data) {
          this.isLoading = false;      
          this.projectDetails = response.project_data;
            if (this.projectDetails && this.projectDetails.length > 0) {
              this.selectedAnalysisType = this.projectDetails[0].analysis_types[0];
              this.projectAnalysisTypes = this.projectDetails[0].analysis_types;
              this.selectProject(this.projectDetails[0].project_id);
            } 
        } else {
          this.isLoading = false;      
        }

      });
    })  
  }

  getSelectedProject(event: any) {
    this.selectProject(event.target.value);
  }

  selectProject(project_id: string) {
    console.log("selecting project"); 
    this.projectViewData = null;   
    this.selectedProject = project_id;
    this.projectDetails.forEach((project) => {
      if (project.project_id === project_id) {
        this.selectedProjectDetails = project;
      }
    })

    this.isLoading = true;
    setTimeout(() => {
      this.backendService.getProjectViewData(this.selectedProject, this.selectedAnalysisType).subscribe((response: any) => {
        if (response.status === 200) {
          this.isLoading = false;
          this.projectViewData = response;
        } else {
          this.isLoading = false;
        }
      });
    })  
  }

  onSelectedAnalysis(event: any) {
    this.selectedAnalysisType = event.target.value;
    this.selectProject(this.selectedProject);
  }
}
